const productionConfig = {
  apiKey: 'AIzaSyDyB-2EFvyyCN2IdKHjoYHLuG7AfcZLc_c',
  authDomain: 'twentyseconds-8ff00.firebaseapp.com',
  projectId: 'twentyseconds-8ff00',
  storageBucket: 'twentyseconds-8ff00.appspot.com',
  messagingSenderId: '990420498038',
  appId: '1:990420498038:web:5b8263809973447eb1d157',
  measurementId: 'G-SHHRMJEELZ',
};

const stagingConfig = {
  apiKey: 'AIzaSyB1ZWate-TL_ixkjDswRqYRe0PQb1VTCYg',
  authDomain: 'twenty-staging-d6ea9.firebaseapp.com',
  projectId: 'twenty-staging-d6ea9',
  storageBucket: 'twenty-staging-d6ea9.appspot.com',
  messagingSenderId: '863310190448',
  appId: '1:863310190448:web:9c9b88dce64c994933e1b5',
  measurementId: 'G-5HYWB0ZDYG',
};

export function getFirebaseConfig() {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line
    console.log('firebase config prod');
    return productionConfig;
  }

  // eslint-disable-next-line
  console.log('firebase config staging');
  return stagingConfig;
}
