import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { GutterFull } from '../../common/components/layout';
import Layout from '../../common/layout';

export default function EmailConfirmPage(): ReactElement | null {
  const pageMeta = { title: 'Email confirmation' };

  return (
    <Layout>
      <GutterFull>
        <Helmet>
          <title>{pageMeta?.title}</title>
        </Helmet>
        <div className="w-full text-center">
          <div className="p-10 text-9xl">📨</div>
          We successfully verfied your email address.
          <br />
          Please head back to the app and finish sign up.
        </div>
      </GutterFull>
    </Layout>
  );
}
