import { gql } from '@apollo/client';

export const FragUploadFileEntity = gql`
  fragment FragUploadFileEntity on UploadFileEntity {
    id
    attributes {
      url
      formats
    }
  }
`;
