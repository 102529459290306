import { Helmet } from 'react-helmet';
import { GutterFull } from '../../common/components/layout';
import Layout from '../../common/layout';

export default function AcctDeletePage() {
  const pageMeta = { title: 'Review' };
  return (
    <Layout>
      <Helmet>
        <title>{pageMeta?.title}</title>
      </Helmet>
      <Body />
    </Layout>
  );
}

export interface ImageGalleryState {
  currentIndex: number;
  currentSlideOffset: number;
  galleryWidth: number;
  isFullscreen: boolean;
  isSwipingThumbnail: boolean;
  isTransitioning: boolean;
}

function Body() {
  return (
    <>
      <div className='pt-20 grow'>
        <GutterFull noTop>
          <div className='pt-4 prose'>
            <h1 className='text-3xl'>Delete Account</h1>
            <p>
              {`We're sorry to see you go! If you have any feedback or issues
              with the site, please let us know at aquadesk.cs@gmail.com`}
            </p>
            <p>
              {`You can delete your account as well in the app. It's in the settings`}
            </p>
          </div>
        </GutterFull>
      </div>
    </>
  );
}
