import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { basicError } from '../utils/error';

export function graphqlErrorHandler({
  fallbackMessage = basicError,
}: {
  fallbackMessage?: string;
} = {}) {
  return (error: ApolloError) => {
    const messages = error?.graphQLErrors?.[0]?.extensions?.exception?.data?.message?.[0]?.messages;

    const userMessage = messages?.map((m: { message?: string }) => m.message)?.join(', ');

    if (userMessage) {
      toast.error(userMessage);
    } else {
      toast.error(fallbackMessage);
    }
  };
}
