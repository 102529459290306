import { gql } from '@apollo/client';
import { FragUsersPermissionsUserEntity } from '../../common/graphql/fragments/user';
import { FragProductReviewItem } from './frag-product-review-item';

export const FragReviewListItem = gql`
  ${FragUsersPermissionsUserEntity}
  ${FragProductReviewItem}

  fragment FragReviewListItem on ReviewListEntity {
    id
    attributes {
      title
      desc
      approved
      updatedAt
      createdAt
      tasteList
      author {
        data {
          ...FragUsersPermissionsUserEntity
        }
      }
      reviews {
        data {
          ...FragProductReviewItem
        }
      }
    }
  }
`;
