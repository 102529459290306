import React from 'react';
import { useHistory } from 'react-router-dom';

export default function ScrollToTop() {
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen((_, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => unlisten();
  }, []);

  return null;
}
