import React from 'react';

import appStoreImg from './assets/app-store.png';
import playStoreImg from './assets/play-store.png';

export default function AppDownloadButtons() {
  return (
    <div className="grid gap-4 grid-cols-2">
      <a
        href="https://apps.apple.com/us/app/twenty-seconds-food-note/id1611514759"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="ios download button" src={appStoreImg} />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.aquadesk.twentyseconds"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="android download button" src={playStoreImg} />
      </a>
    </div>
  );
}
