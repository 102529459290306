import { useLocation } from 'react-router-dom';

export function useSearchParams() {
  const params = new URLSearchParams(useLocation().search);
  return params;
}

export function useSearchParam(keys: string[]): Record<typeof keys[number], string | null> {
  const params = useSearchParams();

  return keys.reduce<Record<typeof keys[number], string | null>>((p, key: string) => {
    p[key] = params.get(key);
    return p;
  }, {});
}

export function useQueryParams<K, T>(keys: K[]): T {
  const params = useSearchParams();

  return keys.reduce<T>((p, key: K) => {
    // @ts-ignore
    p[key] = params.get(key);
    return p;
  }, {} as unknown as T);
}
