import React from 'react';
import { Helmet } from 'react-helmet';
import { GutterFull } from '../common/components/layout';
import Layout from '../common/layout';

export default function PrivacyPolicy() {
  const pageMeta = { title: 'Privacy policy' };

  return (
    <Layout>
      <GutterFull>
        <Helmet>
          <title>{pageMeta?.title}</title>
        </Helmet>
        <iframe title="cookie policy" style={{ width: '100%' }} src="/privacy-policy.html" />
      </GutterFull>
    </Layout>
  );
}
