import { RiMenu2Line } from 'react-icons/ri';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { theme } from './config';
import useBackgroundFreeze from './hooks/use-background-freeze';
import LayoutHeader from './layout-header';
import './layout.css';
import Sidebar from './sidebar';
import bottomWater from '../assets/bottom-water.svg';
import { LayoutContent } from './components/layout';

export default function Layout({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);
  const [headerStyle, setHeaderStyle] = useState({});
  const [freeze, unfreeze] = useBackgroundFreeze();

  useScrollPosition(({ prevPos, currPos }) => {
    const isVisible = currPos.y > -5 || currPos.y > prevPos.y;

    const shouldBeStyle = {
      visibility: isVisible ? 'visible' : 'hidden',
      transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
      transform: isVisible ? 'none' : 'translate(0, -100%)',
      boxShadow: currPos.y > -50 ? '' : '#33333340 0px 1px 14px -5px',
    };

    if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;

    setHeaderStyle(shouldBeStyle);
  }, []);

  useEffect(() => {
    if (open) {
      freeze();
    } else {
      unfreeze();
    }
  }, [open]);

  return (
    <main
      style={{
        backgroundImage: `url(${bottomWater})`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom center',
      }}
      className={classnames([
        'bg-base-100 antialiased absolute w-full min-h-screen',
        {
          'overflow-y-visible': open,
        },
      ])}
    >
      <div className={classnames([theme.maxWidth, 'mx-auto', { 'overflow-y-hidden': open }])}>
        {/* header */}
        <div
          className={classnames([
            theme.maxWidth,
            'opacity-90 fixed w-full bg-base-100',
            {
              'z-30 ': !open,
              toggled: open,
            },
          ])}
          style={headerStyle}
        >
          <div className={classnames([theme.maxWidth, 'w-full flex p-2 items-center'])}>
            <button
              className="tw-btn p-3"
              onClick={() => {
                setOpen(!open);

                freeze();
              }}
            >
              <RiMenu2Line size="1.8em" />
            </button>
            <div className="flex-grow px-3 text-3xl">
              <LayoutHeader />
            </div>
          </div>
        </div>

        {/* sidemenu */}
        <Sidebar sideOpen={open} setSideOpen={setOpen} />
      </div>
      {/* content body */}
      <LayoutContent>{children}</LayoutContent>
    </main>
  );
}
