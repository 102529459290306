import { emailPattern } from '../common/utils/validator';

export const specialCharPattern =
  // eslint-disable-next-line
  /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;

export const upperCasePattern = /[A-Z]+/;
export const numberPattern = /[0-9]+/;

export const passwordConfig = {
  required: 'required',
  minLength: {
    value: 8,
    message: 'at least 8 characters',
  },
  pattern: {
    value: specialCharPattern,
    message: 'must contain a special char',
  },
  validate: {
    'must contain a number': (value?: string) => value && numberPattern.test(value),
    'must contain a uppercase letter': (value?: string) => value && upperCasePattern.test(value),
  },
};

export const emailConfig = {
  required: 'required',
  pattern: {
    value: emailPattern,
    message: 'invalid email',
  },
};

export const usernameConfig = {
  required: 'required',
  maxLength: {
    value: 20,
    message: '20 characters max',
  },
  minLength: {
    value: 3,
    message: 'at least 3 characters',
  },
  validate: (value: string) => !value.match(specialCharPattern) || 'no speicial char',
};

export const loginSuccessMessage = 'login success';
