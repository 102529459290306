import { capitalize } from 'lodash';
import React, { HTMLAttributes } from 'react';
import { RiEmotion2Fill } from 'react-icons/ri';
import { stringSplitter } from '../../common/utils/strapi';

export default function Tastes({
  tasteList,
  tasteData,
  withIcon,
  className,
}: {
  tasteList?: string | null;
  // deprecated field
  tasteData?: (string | null | undefined)[] | null;
  withIcon?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  const tasteItems = tasteList ? tasteList?.split(stringSplitter) : tasteData;

  if (tasteList && tasteList?.length > 0) {
    return (
      <div className={className}>
        {withIcon && <RiEmotion2Fill className="inline mr-2" />}
        {tasteItems?.map((taste) => {
          return (
            <div key={taste} className="badge badge-primary mr-1 text-gray-500">
              {capitalize(taste || '')}
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}
