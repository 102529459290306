import React from 'react';
import { ImagePlaceholder } from './components/media';
import { formatImageUrl } from './utils/media';

export default function ProfileImg({
  url,
  className = 'w-12 h-12',
  onClick,
}: {
  className?: string;
  url?: string | null;
  onClick?: () => void;
}) {
  const image = formatImageUrl(url);

  return (
    <div className="avatar inline" onClick={onClick}>
      <div className={`bg-base-300 mask mask-squircle ${className}`}>
        {image ? (
          <img style={{ margin: 0 }} alt="user profile" src={image} />
        ) : (
          <div className="flex items-center justify-center h-full">
            <ImagePlaceholder size="20px" />
          </div>
        )}
      </div>
    </div>
  );
}
