import { gql } from '@apollo/client';
import { FragUsersPermissionMe } from '../../common/graphql/fragments/user';

export const MeQuery = gql`
  ${FragUsersPermissionMe}

  query Me {
    me {
      ...FragUsersPermissionMe
    }
  }
`;
