import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { event } from '../utils/firebase';

const useGA = () => {
  const location = useLocation();
  useEffect(() => {
    event('page_view');
  }, [location]);
};

export default useGA;
