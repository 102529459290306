import { initializeApp } from 'firebase/app';
import {
  logEvent,
  isSupported,
  Analytics,
  initializeAnalytics,
  getAnalytics,
} from 'firebase/analytics';
import { getFirebaseConfig } from './firebase-config';

export const app = initializeApp(getFirebaseConfig());
let analytics: Analytics | null;

export const event = async (
  eventName: string,
  // eslint-disable-next-line
  eventParams?: { [key: string]: any }
) => {
  if (await isSupported()) {
    if (!analytics) {
      try {
        analytics = initializeAnalytics(app, {
          config: { send_page_view: false },
        });
      } catch (e) {
        analytics = getAnalytics(app);
      }
    }

    if (analytics) {
      logEvent(analytics, eventName, eventParams);
    }
  }
};
