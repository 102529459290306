/* eslint-disable no-fallthrough */
import { FormatsEnum } from '../../types';
import { envs } from '../config';
import { FragUploadFileEntity } from '../graphql/fragments/__generated__/FragUploadFileEntity';

const cloudStorageUrl = 'https://storage.googleapis.com/twenty-seconds-prod';

export function formatImageUrl(url?: string | null) {
  if (!url) {
    return undefined;
  }

  // urls that are being stored in db by strapi
  if (url.startsWith(cloudStorageUrl)) {
    return url.replace(cloudStorageUrl, `${envs.ImgCdnUrl}`);
  }

  if (
    ['blob:', 'http', 'data:'].find((t) => {
      return url.startsWith(t);
    })
  ) {
    return url;
  }

  return `${envs.ImgCdnUrl}${url}`;
}

export function getSizedMediaLink(
  cover?: FragUploadFileEntity | undefined,
  size: FormatsEnum = FormatsEnum.medium,
) {
  if (!cover) return null;

  let sizedUrl;

  /**
   * strapi stores the maximum format sized for a given image uploads
   * i.e. when a small image is get uploaded, it does not create medium or large.
   */
  switch (size) {
    // @ts-ignore no-switch-case-fall-through
    case FormatsEnum.large:
      sizedUrl = cover?.attributes?.formats?.large?.url;
      if (sizedUrl) break;
    // @ts-ignore no-switch-case-fall-through
    case FormatsEnum.medium:
      sizedUrl = cover?.attributes?.formats?.medium?.url;
      if (sizedUrl) break;
    // @ts-ignore no-switch-case-fall-through
    case FormatsEnum.small:
      sizedUrl = cover?.attributes?.formats?.small?.url;
      if (sizedUrl) break;
    // @ts-ignore no-switch-case-fall-through
    case FormatsEnum.thumbnail:
      sizedUrl = cover?.attributes?.formats?.thumbnail?.url;
      if (sizedUrl) break;
    default:
      sizedUrl = cover?.attributes?.url;
  }

  return formatImageUrl(sizedUrl);
}

export const AppIcon =
  'https://storage.googleapis.com/aquadesk.com/static/appIcons/app-logo-shadow.png';
