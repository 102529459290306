export const isServer = () => typeof window === 'undefined';

export function getToken(
  // eslint-disable-next-line
  res?: any
) {
  return typeof window !== 'undefined'
    ? // client
      window.rrr
    : res?.locals?.refreshToken;
}

// eslint-disable-next-line
export function setToken(_token?: string | null, res?: any) {
  if (isServer()) {
    if (res) {
      res.locals.refreshToken = _token;
    }
  } else {
    window.rrr = _token;
  }
}
