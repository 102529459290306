import { gql } from '@apollo/client';
import { FragReviewListItem } from './frag-review-list-item';

export const REVIEW_LIST = gql`
  ${FragReviewListItem}

  query ReviewList($id: ID!) {
    reviewList(id: $id) {
      data {
        ...FragReviewListItem
      }
    }
  }
`;
