import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiStore } from 'react-icons/bi';
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router';

import AppDownloadButtons from '../common/components/AppDownloadButtons';
import { GutterFull } from '../common/components/layout';
import { LoadingCentered } from '../common/components/loading';
import ReviewRating from '../common/components/Rating';
import { FragUploadFileEntity } from '../common/graphql/fragments/__generated__/FragUploadFileEntity';
import Layout from '../common/layout';
import ProfileImg from '../common/profile-img';
import { getSizedMediaLink } from '../common/utils/media';
import { stringSplitter } from '../common/utils/strapi';
import { FormatsEnum } from '../types';
import Tastes from './components/Tastes';
import { PRODUCT_REVIEW } from './gql/product-review';
import {
  ProductReview,
  ProductReviewVariables,
} from './gql/__generated__/ProductReview';

export default function ReviewViewPage() {
  const pageMeta = { title: 'Review' };
  return (
    <Layout>
      <Helmet>
        <title>{pageMeta?.title}</title>
      </Helmet>
      <Body />
    </Layout>
  );
}

export interface ImageGalleryState {
  currentIndex: number;
  currentSlideOffset: number;
  galleryWidth: number;
  isFullscreen: boolean;
  isSwipingThumbnail: boolean;
  isTransitioning: boolean;
}

function Body() {
  const [current, setCurrent] = useState<number>(0);
  const pageMeta = { title: 'Review' };
  const params = useParams<ProductReviewVariables>();
  const [fullGallery, setFullGallery] = useState(false);

  const result = useQuery<ProductReview, ProductReviewVariables>(
    PRODUCT_REVIEW,
    {
      variables: { id: params?.id },
      fetchPolicy: 'cache-first',
    }
  );

  if (result.error) {
    return (
      <GutterFull>
        <div className='grow text-center'>
          <div className='text-9xl'>🥣</div>
          <div className='center'>
            There was an issue. Could have been deleted or un-shared.
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className='py-5'>
            <AppDownloadButtons />
          </div>
        </div>
      </GutterFull>
    );
  }

  if (result.loading) {
    return (
      <GutterFull>
        <LoadingCentered />
      </GutterFull>
    );
  }

  const data = result.data?.productReview?.data?.attributes;
  if (data) {
    pageMeta.title = `${pageMeta.title}: ${data?.productName}`;
  }

  const images = data?.images?.data || [];
  const items = images.map((i) => {
    return {
      fullscreen: getSizedMediaLink(i, FormatsEnum.large) || '',
      original: getSizedMediaLink(i, FormatsEnum.medium) || '',
    };
  });

  const profileUrl = getSizedMediaLink(
    data?.author?.data?.attributes?.photo?.data as FragUploadFileEntity,
    FormatsEnum.thumbnail
  );

  const gallery = (
    <ImageGallery
      key='gallery'
      items={items}
      startIndex={current}
      showPlayButton={false}
      showNav
      showFullscreenButton={false}
      onClick={() => {
        setFullGallery(!fullGallery);
      }}
      lazyLoad
      onSlide={(idx) => {
        setCurrent(idx);
      }}
      renderItem={(item) => {
        return (
          <div className='h-full'>
            <img
              alt='thumbnail'
              src={fullGallery ? item.fullscreen : item.original}
              style={{
                height: fullGallery ? '100vh' : 400,
                width: '100%',
                objectFit: fullGallery ? 'contain' : 'cover',
              }}
            />
          </div>
        );
      }}
    />
  );

  if (fullGallery) {
    return (
      <div className='fixed z-40 left-0 top-0 w-full h-full bg-black'>
        {gallery}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageMeta?.title}</title>
      </Helmet>
      <div className='grow pt-20'>
        {gallery}
        <div className='relative -top-8'>
          <div className='absolute flex p-3 w-full h-0 overflow-visible'>
            {items.map((item, itemIdx) => {
              const active = itemIdx === current;
              return (
                <div
                  key={`item_${itemIdx}`}
                  className='shrink flex-1 h-2 opacity-50'
                >
                  <div
                    className={classNames(
                      'm-[1px] h-[3px] bg-white rounded-sm',
                      active ? 'opacity-100' : 'opacity-30'
                    )}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>

        <GutterFull noTop>
          <div className='prose pt-4'>
            <h2 style={{ marginBottom: '0.6rem' }}>{data?.productName}</h2>
            <div className='relative z-10 inline-block p-2 pr-3 bg-white rounded-xl shadow-md'>
              <div className='flex flex-row items-center bg-white space-x-3'>
                <div className='flex-grow-0'>
                  <ProfileImg className='w-8 h-8' url={profileUrl} />
                </div>
                <div className='flex-grow'>
                  <div className='flex flex-col'>
                    <div className='flex-grow-0'>
                      <span className='text-gray-500 font-semibold'>
                        {data?.author?.data?.attributes?.username}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='prose relative -top-5 ml-3 mt-3 p-3 pt-4 whitespace-pre-wrap text-xl border border-gray-100 rounded-lg shadow-sm'>
              <ReviewRating value={data?.rating} />

              <Tastes
                className='my-2'
                tasteList={data?.tasteList}
                tasteData={data?.tastes?.data?.map((t) => t.attributes?.name)}
                withIcon
              />

              {data?.storeList && data?.storeList?.length > 0 && (
                <div className='my-2'>
                  <BiStore className='inline' />{' '}
                  {data?.storeList?.split(stringSplitter)?.map((taste) => {
                    return (
                      <div
                        key={taste}
                        className='badge badge-primary mr-1 text-red-500'
                      >
                        {taste}
                      </div>
                    );
                  })}
                </div>
              )}
              <div>{data?.desc}</div>
            </div>

            <br />
            <div className='text-sm opacity-20'>created: {data?.createdAt}</div>
            <div className='text-sm opacity-20'>updated: {data?.updatedAt}</div>

            <AppDownloadButtons />
          </div>
        </GutterFull>
      </div>
    </>
  );
}
