import { gql } from '@apollo/client';
import { FragUploadFileEntity } from '../../common/graphql/fragments/frag-upload-file-entity';
import { FragUsersPermissionsUserEntity } from '../../common/graphql/fragments/user';
import { FragTasteEntity } from './frag-taste-entity';

export const FragProductReviewItem = gql`
  ${FragUploadFileEntity}
  ${FragTasteEntity}
  ${FragUsersPermissionsUserEntity}

  fragment FragProductReviewItem on ProductReviewEntity {
    id
    attributes {
      productName
      rating
      desc
      updatedAt
      createdAt
      images {
        data {
          ...FragUploadFileEntity
        }
      }
      storeList
      tasteList
      tastes {
        data {
          ...FragTasteEntity
        }
      }
      author {
        data {
          ...FragUsersPermissionsUserEntity
        }
      }
    }
  }
`;
