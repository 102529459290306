import React from 'react';
import Layout from '../layout';
import { GutterFull } from './layout';

export default function NotFoundPage() {
  return (
    <Layout>
      <GutterFull>
        <div>404 Page not found</div>
      </GutterFull>
    </Layout>
  );
}
