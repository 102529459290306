import React from 'react';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

type Props = {
  showPwd: boolean;
  setShowPwd: (value: boolean) => void;
};

const size = '2.4rem';

export default function PasswordShowIcon({ showPwd, setShowPwd }: Props) {
  return !showPwd ? (
    <RiEyeFill
      size={size}
      className="p-2 cursor-pointer"
      onClick={() => {
        setShowPwd(true);
      }}
    />
  ) : (
    <RiEyeOffFill
      size={size}
      className="p-2 cursor-pointer"
      onClick={() => {
        setShowPwd(false);
      }}
    />
  );
}
