/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Link } from 'react-router-dom';
import AppDownloadButtons from '../common/components/AppDownloadButtons';
import { GutterFull } from '../common/components/layout';
import { LoadingCentered } from '../common/components/loading';
import ReviewRating from '../common/components/Rating';
import { FragUploadFileEntity } from '../common/graphql/fragments/__generated__/FragUploadFileEntity';
import Layout from '../common/layout';
import ProfileImg from '../common/profile-img';
import { getSizedMediaLink } from '../common/utils/media';
import { FormatsEnum } from '../types';
import Tastes from './components/Tastes';
import { REVIEW_LIST } from './gql/review-list';
import { ProductReviewVariables } from './gql/__generated__/ProductReview';
import {
  ReviewList,
  ReviewListVariables,
} from './gql/__generated__/ReviewList';

export default function ListViewPage() {
  const pageMeta = { title: 'List' };
  return (
    <Layout>
      <Helmet>
        <title>{pageMeta?.title}</title>
      </Helmet>
      <Body />
    </Layout>
  );
}

function Body() {
  const pageMeta = { title: 'Review' };
  const params = useParams<ProductReviewVariables>();

  const result = useQuery<ReviewList, ReviewListVariables>(REVIEW_LIST, {
    variables: { id: params?.id },
    fetchPolicy: 'cache-first',
  });

  if (result.error) {
    return (
      <GutterFull>
        <div className='grow text-center'>
          <div className='text-9xl'>🥣</div>
          <div className='center'>
            Oops! There has been an issue. Please try it out later.
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className='py-5'>
            <AppDownloadButtons />
          </div>
        </div>
      </GutterFull>
    );
  }

  if (result.loading) {
    return (
      <GutterFull>
        <LoadingCentered />
      </GutterFull>
    );
  }

  const data = result.data?.reviewList?.data?.attributes;
  if (data) {
    pageMeta.title = `${pageMeta.title}: ${data?.title}`;
  }

  const profileUrl = getSizedMediaLink(
    data?.author?.data?.attributes?.photo?.data as FragUploadFileEntity,
    FormatsEnum.thumbnail
  );

  return (
    <>
      <Helmet>
        <title>{pageMeta?.title}</title>
      </Helmet>
      <div className='grow pt-20'>
        <GutterFull noTop>
          <div className='pt-4'>
            <div className='prose'>
              <h2 style={{ marginBottom: '0.6rem' }}>{data?.title}</h2>
              <div className='relative z-10 inline-block p-2 pr-3 bg-white rounded-xl shadow-md'>
                <div className='flex flex-row items-center bg-white space-x-3'>
                  <div className='flex-grow-0'>
                    <ProfileImg className='w-8 h-8' url={profileUrl} />
                  </div>
                  <div className='flex-grow'>
                    <div className='flex flex-col'>
                      <div className='flex-grow-0'>
                        <span className='text-gray-500 font-semibold'>
                          {data?.author?.data?.attributes?.username}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data?.desc ? (
              <div className='prose relative -top-5 ml-3 mt-3 p-3 pt-4 whitespace-pre-wrap text-xl border border-gray-100 rounded-lg shadow-sm'>
                <div>{data?.desc}</div>
              </div>
            ) : (
              <div className='h-4' />
            )}
            {/* reviews in the list */}

            <h4 className='font-bold'>Reviews</h4>
            <div className='h-2' />

            <div className='flex flex-col space-y-2'>
              {data?.reviews?.data?.map((review) => {
                const firstImg = review.attributes?.images?.data?.[0];
                const thumbUrl = getSizedMediaLink(
                  firstImg,
                  FormatsEnum.thumbnail
                );

                return (
                  <Link
                    to={`/reviews/${review.id}`}
                    key={review.id}
                    className='bg-base-200 flex p-4 rounded-xl'
                  >
                    <div className='w-24 h-24 rounded-xl overflow-hidden'>
                      {thumbUrl ? (
                        <img
                          src={thumbUrl}
                          className='w-full h-full object-cover'
                          alt='product thumb'
                        />
                      ) : null}
                    </div>
                    <div className='w-3' />
                    <div className='flex flex-1 flex-col'>
                      <div className='flex'>
                        <ReviewRating value={review.attributes?.rating || 0} />
                      </div>
                      <div className='text-lg font-semibold'>
                        {review.attributes?.productName}
                      </div>
                      <div className=''>
                        <Tastes
                          tasteList={review.attributes?.tasteList}
                          tasteData={review.attributes?.tastes?.data?.map(
                            (t) => t.attributes?.name
                          )}
                        />
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>

            <br />
            <div className='text-sm opacity-20'>created: {data?.createdAt}</div>
            <div className='text-sm opacity-20'>updated: {data?.updatedAt}</div>
            <br />

            <AppDownloadButtons />
          </div>
        </GutterFull>
      </div>
    </>
  );
}
