import { gql } from '@apollo/client';
import { FragUploadFileEntity } from './frag-upload-file-entity';

export const FragUsersPermissionMe = gql`
  fragment FragUsersPermissionMe on UsersPermissionsMe {
    id
    username
    email
    blocked
    confirmed
    photo {
      id
      attributes {
        url
      }
    }
  }
`;

export const FragUsersPermissionsUserEntity = gql`
  ${FragUploadFileEntity}

  fragment FragUsersPermissionsUserEntity on UsersPermissionsUserEntity {
    id
    attributes {
      username
      photo {
        data {
          ...FragUploadFileEntity
        }
      }
    }
  }
`;
