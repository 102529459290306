import {
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  QueryResult,
  useLazyQuery,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { MeQuery } from '../../auth/components/me-query';
import { User } from '../../types';
import { useSearchParam } from './use-query-params';

export interface Data {
  me: User | undefined;
}

interface Vars {
  id: string;
}

export function useMe(
  fetchPolicy: WatchQueryFetchPolicy | undefined = 'cache-only',
): [Data['me'], QueryResult<Data, Vars>] {
  const result = useQuery<Data, Vars>(MeQuery, {
    fetchPolicy,
  });

  const { data } = result;

  return [data?.me, result];
}

// export declare function useLazyQuery<
//   TData = any,
//   TVariables = OperationVariables
// >(
//   query: DocumentNode | TypedDocumentNode<TData, TVariables>,
//   options?: LazyQueryHookOptions<TData, TVariables>
// ): QueryTuple<TData, TVariables>;

interface LazyMeParams {
  onSuccess?: (data?: Data) => void;
}

export function useLazyMe({ onSuccess }: LazyMeParams = {}): [
  (options?: QueryLazyOptions<Vars>) => void,
  User | undefined,
  LazyQueryResult<Data, OperationVariables>,
] {
  const [getMe, result] = useLazyQuery<Data>(MeQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
    },
  });

  const { data } = result;

  return [getMe, data?.me, result];
}

/**
 * This should be called once on auth required pages
 * This makes RPC call
 */
export function useAuthRedirect({
  authed = true,
  to: toPure = '/auth/profile',
}: {
  authed?: boolean;
  to?: string;
} = {}) {
  const history = useHistory();
  const queries = useSearchParam(['_re']);
  const to = decodeURIComponent(queries?._re || '') || toPure;

  const [me, { loading }] = useMe('cache-and-network');

  useEffect(() => {
    if (loading) return;

    if (!authed && !me) {
      history.replace(to);
    }

    if (authed && me) {
      history.replace(to);
    }
  }, [me, loading, to]);
}
