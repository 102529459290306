import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './layout.css';

function Item({ postFix, label }: { label: string; postFix?: string }) {
  return (
    <div className="flex flex-row content-between items-center">
      <div className="flex-grow"></div>
      <div className="flex-grow">{label}</div>
      <div className="flex-grow-0 opacity-60">{postFix}</div>
    </div>
  );
}

export default function LayoutHeader() {
  return (
    <Switch>
      <Route path="/reviews">
        <Item label="Review" postFix="📝" />
      </Route>
      <Route path="/lists">
        <Item label="List" postFix="🗂️" />
      </Route>
      <Route path="/auth">
        <Item label="Twenty Seconds" postFix="🔑" />
      </Route>
      <Route path="/pages/privacy-policy">
        <Item label="Privacy policy" postFix="🔐" />
      </Route>
      <Route path="/pages/cookie-policy">
        <Item label="Cookie policy" postFix="🍪" />
      </Route>
      <Route path="/">
        <Item label="Welcome" postFix="🍲" />
      </Route>
      <Route path="*">
        <Item label="Un - oh" postFix="🦑" />
      </Route>
    </Switch>
  );
}
