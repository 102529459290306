import { gql } from '@apollo/client';

export const FragTasteEntity = gql`
  fragment FragTasteEntity on TasteEntity {
    id
    attributes {
      name
    }
  }
`;
