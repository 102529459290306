export default function useBackgroundFreeze() {
  return [
    function freeze() {
      if (document != null && document.querySelector) {
        // @ts-ignore
        document.querySelector('body').style.setProperty('overflow', 'hidden');
      }
    },
    function unfreeze() {
      if (document != null && document.querySelector) {
        // @ts-ignore
        document.querySelector('body').style.setProperty('overflow', 'auto');
      }
    },
  ];
}
