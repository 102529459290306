import { Rating } from 'react-simple-star-rating';
import styled from 'styled-components';
import { theme } from '../config';

const RatingWrap = styled.div`
  display: block;
  svg {
    display: initial;
  }
`;

export default function ReviewRating({ value }: { value?: number | null }) {
  return (
    <RatingWrap>
      <Rating
        fillColor={theme.primaryColor}
        initialValue={value || 0}
        size={20}
        readonly
        transition
        allowFraction
      />
    </RatingWrap>
  );
}
