import { gql } from '@apollo/client';
import { FragUsersPermissionMe } from '../../common/graphql/fragments/user';

export const RESET_PASSWORD = gql`
  ${FragUsersPermissionMe}

  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
      user {
        ...FragUsersPermissionMe
      }
    }
  }
`;
