import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { theme } from '../config';

export function LayoutContent({ children }: { children?: ReactNode }) {
  return (
    <div
      className={classNames(
        theme.maxWidth,
        'z-30 flex flex-grow mx-auto p-0 w-full min-h-screen shadow',
      )}
    >
      {children}
    </div>
  );
}

export function GutterFull({ children, noTop }: { children?: ReactNode; noTop?: boolean }) {
  return (
    <div className={classNames('flex flex-grow pb-20 pt-28 px-5', noTop ? 'pt-0' : 'pt-28')}>
      {children}
    </div>
  );
}

export function HalfGutter({ children }: { children?: ReactNode }) {
  return <div className={classNames('pt-[4.3rem] flex flex-grow pb-20 px-2')}>{children}</div>;
}

export function NoGutter({ children, className }: { children?: ReactNode; className?: string }) {
  return (
    <div className={classNames('pt-[4.3rem] flex flex-grow pb-20 px-0', className)}>{children}</div>
  );
}

export function HorizLine() {
  return (
    <div className="py-8">
      <div className={`h-[1px] max-w-[140px] w-1/2 mx-auto bg-base-200`} />
    </div>
  );
}
