export const envs = {
  // client
  appUrl: process.env.REACT_APP_APP_URL,
  ImgCdnUrl: process.env.REACT_APP_IMG_CDN_URL || process.env.REACT_APP_BACKEND_BASE,
  BackendUrl: process.env.REACT_APP_BACKEND_URL,
  BackendBase: process.env.REACT_APP_BACKEND_BASE,
};

export const theme = {
  iconSize: '1.4rem',
  maxWidth: 'max-w-lg',
  primaryColor: '#8AE7C5',
};
