import classNames from 'classnames';
import React from 'react';
import {
  FieldError,
  FieldErrors,
  UseFormRegisterReturn,
} from 'react-hook-form';

export function FormControl({
  formError,
  formProps,
  id,
  type = 'text',
  label,
  inputRender,
  tabIndex,
  icon,
}: {
  formError: FieldErrors;
  id: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  label?: string;
  formProps?: UseFormRegisterReturn;
  tabIndex?: number;
  icon?: React.ReactNode;
  inputRender?: (inputProps: {
    id: typeof id;
    type?: typeof type;
    label?: typeof label;
    error?: FieldError;
  }) => React.ReactNode;
}) {
  const error = (formError?.[id as string] || {}) as FieldError;

  return (
    <div className='form-control'>
      <label htmlFor={id as string} className='label'>
        <span className='label-text capitalize'>{label || id}</span>
      </label>
      {inputRender ? (
        inputRender({ id, type, label, error })
      ) : (
        <div className='relative flex flex-col'>
          <input
            type={type}
            tabIndex={tabIndex}
            id={id as string}
            autoComplete={id as string}
            className={classNames('input input-bordered', {
              'input-error': error?.type,
              'pr-12': icon,
            })}
            defaultValue=''
            {...formProps}
          />
          {icon && (
            <div className='absolute right-0 flex items-center px-2 h-full'>
              {icon}
            </div>
          )}
        </div>
      )}

      {(!!error?.message || !!error?.type) && (
        <label className='label'>
          <span className='text-error'>{error?.message || error?.type}</span>
        </label>
      )}
    </div>
  );
}
