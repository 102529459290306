import { gql } from '@apollo/client';
import { FragProductReviewItem } from './frag-product-review-item';

export const PRODUCT_REVIEW = gql`
  ${FragProductReviewItem}

  query ProductReview($id: ID!) {
    productReview(id: $id) {
      data {
        ...FragProductReviewItem
      }
    }
  }
`;
