import { Helmet } from 'react-helmet';
import { GutterFull } from '../common/components/layout';
import Layout from '../common/layout';

export default function CommGuide() {
  const pageMeta = { title: 'Community Guide' };

  return (
    <Layout>
      <GutterFull>
        <Helmet>
          <title>{pageMeta?.title}</title>
        </Helmet>
        <iframe title="Community Guideline" style={{ width: '100%' }} src="/comm-guide.html" />
      </GutterFull>
    </Layout>
  );
}
