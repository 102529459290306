import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import EmailConfirmPage from '../auth/components/email-confirm-page';
import ResetPasswordPage from '../auth/components/reset-password-page';
import AcctDeletePage from '../modules/account/AcctDeletePage';
import CommGuide from '../pages/CommGuide';
import CookiePolicy from '../pages/CookiePolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Welcome from '../pages/Welcome';
import ListViewPage from '../review/ListViewPage';
import ReviewViewPage from '../review/ReviewViewPage';
import NotFoundPage from './components/NotFoundPage';
import { Meta } from './components/metatag';
import { useMe } from './hooks/use-me';
import useGA from './hooks/useGA';
import ScrollToTop from './scroll-top';
import { AppIcon } from './utils/media';

const Routes = () => {
  useMe('cache-first');
  useGA();

  return (
    <div data-theme='aquadesk' className='text-base-content'>
      <Meta title='Welcome to Aquadesk' image={AppIcon} />
      {/* <div data-theme="forest"> */}
      <ScrollToTop />

      <Switch>
        <Route exact path='/delete' component={AcctDeletePage} />
        <Route exact path='/reviews/:id' component={ReviewViewPage} />
        <Route exact path='/lists/:id' component={ListViewPage} />
        <Route exact path='/pages/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/pages/cookie-policy' component={CookiePolicy} />
        <Route exact path='/pages/comm-guide' component={CommGuide} />
        <Route
          exact
          path='/auth/email-confirmation'
          component={EmailConfirmPage}
        />
        <Route path='/auth/reset-password' component={ResetPasswordPage} />
        <Route exact path='/' component={Welcome} />
        <Route path='*' component={NotFoundPage} />
      </Switch>

      <ToastContainer
        icon={false}
        closeButton={false}
        autoClose={3000}
        hideProgressBar
        position='bottom-right'
      />
    </div>
  );
};

export default Routes;
