import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { theme } from './config';
import './layout.css';

const Item = ({
  menu,
  active,
}: {
  menu: { label: string; link: string };
  active: boolean;
}) => {
  const history = useHistory();
  return (
    <div>
      <div
        className={classnames([
          'text-right w-full p-5 border-r-8 cursor-pointer hover:border-opacity-100 hover:font-bold',
          active ? 'border-base-300' : 'border-transparent',
        ])}
        onClick={() => {
          history.push(`${menu.link}`);
        }}
      >
        {menu.label}
      </div>
      <div>
        <div className={`h-[1px] w-1/2 ml-auto bg-base-200 `} />
      </div>
    </div>
  );
};

export default function Sidebar({
  sideOpen,
  setSideOpen,
}: {
  sideOpen?: boolean;
  setSideOpen: (t: boolean) => void;
}) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div
      className={classnames([
        'fixed w-full h-full top-0 left-0',
        sideOpen ? 'z-20' : 'z-[-10]',
      ])}
    >
      <div
        className={classnames([
          theme.maxWidth,
          'overflow-hidden min-h-full mx-auto',
        ])}
      >
        {/* backdrop shade */}
        <div
          className={classnames([
            theme.maxWidth,
            'fixed top-0 w-full h-full bg-base-content opacity-50',
          ])}
          onClick={() => {
            setSideOpen(!sideOpen);
          }}
        />
        {/* sidebar */}
        <div
          className={classnames([
            'flex fixed h-full max-w-xs w-full overflow-hidden',
          ])}
        >
          <div
            className={classnames([
              'flex flex-col flex-nowrap content-between sidemenu shadow bg-base-100 tw-sidebar h-full max-w-xs w-full',
              { active: sideOpen },
            ])}
          >
            {/* header */}
            <div className='flex shadow'>
              <div
                className={classnames([
                  'p-5 flex-grow text-center text-xl font-bold cursor-pointer',
                ])}
                onClick={() => {
                  history.push('/');
                }}
              >
                Twenty Seconds
              </div>
              <div
                className='self-center p-3 cursor-pointer'
                onClick={() => {
                  setSideOpen(!sideOpen);
                }}
              >
                ✌️
              </div>
            </div>
            {/* menus */}
            <div className='flex-grow overflow-auto'>
              {[
                // { label: t('About'), link: '/about' },
                { label: t('Privacy policy'), link: '/pages/privacy-policy' },
                { label: t('Cookie policy'), link: '/pages/cookie-policy' },
                { label: t('Community Guideline'), link: '/pages/comm-guide' },
                { label: t('Delete account'), link: '/delete' },
              ].map((menu) => {
                const active = location.pathname.startsWith(menu.link);
                return <Item key={menu.label} active={active} menu={menu} />;
              })}
            </div>
            {/* footer */}
            <div
              className='p-5 text-center cursor-pointer text-base-content bg-base-300 opacity-30'
              onClick={() => {
                window.open('https://twentyseconds.app');
              }}
            >
              twentyseconds.app
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
