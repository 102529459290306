import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FormControl } from '../../common/components/form-element';
import { GutterFull } from '../../common/components/layout';
import { graphqlErrorHandler } from '../../common/graphql/error-handlers';
import { useSearchParams } from '../../common/hooks/use-query-params';
import { setToken } from '../../common/hooks/use-token';
import Layout from '../../common/layout';
import { passwordConfig } from '../config';
import { RESET_PASSWORD } from '../gql/reset-password';
import { ResetPassword, ResetPasswordVariables } from '../gql/__generated__/ResetPassword';
import PasswordShowIcon from '../../common/components/icons/show-password-icon';
import { basicError } from '../../common/utils/error';

export default function ResetPasswordPage() {
  const code = useSearchParams().get('code') || '';

  const [showPwd, setShowPwd] = useState(false);

  const [resetPasswordMut, { loading }] = useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD,
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors: formError },
  } = useForm<ResetPasswordVariables>();

  const onSubmit: SubmitHandler<ResetPasswordVariables> = async (input) => {
    await resetPasswordMut({
      variables: { ...input, code },
    })
      .then(async ({ data }) => {
        if (data?.resetPassword?.jwt) {
          // login success
          setToken(data.resetPassword.jwt || null);
          toast('Successful! Please go ahead and login.');
        } else {
          toast.error(basicError);
        }
      })
      .catch(
        graphqlErrorHandler({
          fallbackMessage: basicError,
        }),
      );
  };

  const pageMeta = {
    title: 'Create A Strong Password',
    // desc: 'Your password must be more than eight characters and include a combination of numbers, letters and special characters (!$@%).',
    desc: 'Your password must be more than eight characters and include a combination of numbers, uppercase and lowercase letters and some special characters.',
  };

  if (!code) {
    return (
      <Layout>
        <GutterFull>something went wrong</GutterFull>
      </Layout>
    );
  }

  return (
    <Layout>
      <GutterFull>
        <Helmet>
          <title>{pageMeta?.title}</title>
        </Helmet>
        <div className="center w-full">
          <div className="w-full">
            {pageMeta && (
              <div>
                <div className="mb-3 text-center text-3xl font-extrabold leading-normal">
                  {pageMeta?.title}
                </div>
                <div className="mb-3 text-center">{pageMeta?.desc}</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid gap-3 grid-cols-1">
                    <FormControl
                      id="password"
                      type={showPwd ? 'text' : 'password'}
                      formError={formError}
                      formProps={register('password', passwordConfig)}
                      icon={<PasswordShowIcon showPwd={showPwd} setShowPwd={setShowPwd} />}
                    />
                    <FormControl
                      id="passwordConfirmation"
                      label="Password Confirm"
                      type={showPwd ? 'text' : 'password'}
                      formError={formError}
                      icon={<PasswordShowIcon showPwd={showPwd} setShowPwd={setShowPwd} />}
                      formProps={register('passwordConfirmation', {
                        ...passwordConfig,
                        validate: (value) => {
                          return value === getValues('password') || 'password does not match';
                        },
                      })}
                    />
                    <button
                      type="submit"
                      className={classNames([
                        'btn btn-primary',
                        {
                          loading,
                        },
                      ])}
                    >
                      Reset password
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </GutterFull>
    </Layout>
  );
}
