import React from 'react';
import { Helmet } from 'react-helmet';
import AppDownloadButtons from '../common/components/AppDownloadButtons';

import { GutterFull } from '../common/components/layout';
import Layout from '../common/layout';

export default function Welcome() {
  const pageMeta = { title: 'Welcome' };

  return (
    <Layout>
      <GutterFull>
        <Helmet>
          <title>{pageMeta?.title}</title>
        </Helmet>
        <div className="w-full text-center">
          <div className="text-6xl">🍜 ➕ 📝</div>
          <div className="text-xl">
            <br />
            <div className="text-2xl">“Have I tried this one before?”</div>
            <br />
            <div className="text-2xl">“Was this the brand that was too salty?”</div>
            <br />
            <div className="text-4xl">Wonder no more!</div>
            <br />
            <div className="py-5">
              <AppDownloadButtons />
            </div>
            <br />
            Twenty Seconds will save you time and money by keeping track of your hits and misses so
            you don’t buy the same unappetizing product again and again.
            <br />
            <br />
            Just snap a picture of the product and rate it. You can also select taste notes and
            write a description or notes on preparing it for your reference.
            <br />
            <br />
            While you’re at the market, you can also search the name of the product to see if you
            had purchased it before!
            <br />
            <br />
            We hope Twenty Seconds will save you at least 20 seconds during your shopping trip.
            <br />
            <br />
            <div className="text-5xl">Bon appetite!</div>
          </div>
        </div>
      </GutterFull>
    </Layout>
  );
}
