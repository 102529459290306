import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloLink,
  concat,
  InMemoryCache,
  // gql,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { getToken } from '../common/hooks/use-token';
import fetch from 'isomorphic-unfetch';
import { envs } from '../common/config';

const option = {
  typePolicies: {
    Query: {
      fields: {},
    },
  },
};

// export const typeDefs = gql``;

const httpLink = createUploadLink({
  // Server URL (must be absolute)
  uri: envs.BackendUrl,
  fetchOptions: {
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options
    // origin: [
    //   'http://localhost:3012',
    //   'http://localhost:3011',
    //   'http://localhost:1401',
    // ],
    credentials: 'omit',
  },
  // Use fetch() polyfill on the server
  // @ts-ignore
  fetch,
});

// eslint-disable-next-line
const createAuthMiddleware = (res?: any) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      // token in memory
      const token = getToken(res);

      return {
        headers: {
          ...headers,
          ...(token
            ? {
                authorization: `Bearer ${token}`,
              }
            : null),
          // 'Access-Control-Allow-Origin': '*',
        },
      };
    });

    return forward(operation);
  });

// eslint-disable-next-line
export function createClient(res?: any) {
  const client = new ApolloClient<NormalizedCacheObject>({
    /* connectToDevTools: process?.browser, */
    // @ts-ignore
    link: concat(createAuthMiddleware(res), httpLink),
    // typeDefs,
    cache: new InMemoryCache(option),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return client;
}
