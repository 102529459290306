import React from 'react';
import { Helmet } from 'react-helmet';

export function Meta({
  title,
  image,
  desc,
}: {
  image?: string | undefined | null;
  title?: string | undefined | null;
  desc?: string | undefined | null;
} = {}) {
  return (
    <Helmet>
      {!!title && <title>{title}</title>}
      {!!title && <meta property="og:title" content={title} />}
      {!!image && <meta property="og:image" content={image} />}
      {!!desc && <meta property="og:description" content={desc} />}
    </Helmet>
  );
}
